// Avoid `console` errors in browsers that lack a console.
(function () {
  var method;
  var noop = function () {};
  var methods = [
    "assert",
    "clear",
    "count",
    "debug",
    "dir",
    "dirxml",
    "error",
    "exception",
    "group",
    "groupCollapsed",
    "groupEnd",
    "info",
    "log",
    "markTimeline",
    "profile",
    "profileEnd",
    "table",
    "time",
    "timeEnd",
    "timeline",
    "timelineEnd",
    "timeStamp",
    "trace",
    "warn",
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

// Place any plugins in here.

/*
 * * * * * * * * * * * * * * * * * * * * * *
 *                                         *
 *                                         *
 *        CMP Video Consent Plugin         *
 *                                         *
 *                                         *
 * * * * * * * * * * * * * * * * * * * * * *
 */

const CMPVideoConsent = function (config) {
  const CMPVideoConsent = {
    cmpId: "ba2a0f3c22a4",
    wrapperId: "",
    platform: "",
    embedCode: "",
    useBootstrap: false,
    youtubeAllowed: false,
    vimeoAllowed: false,
    cmpLoaded: false,
    wasVideoAllowed: null,
    vendorIds: {
      youtube: 's30',
      vimeo: 's77'
    },
    init: function (config) {
      const that = this;
      this.wrapperId = config.wrapperId;
      this.platform = config.platform;
      this.embedCode = config.embedCode;
      if (config.cmpId) {
        this.cmpId = config.cmpId;
      }
      if(config.vendorIds) {
        this.vendorIds = config.vendorIds;
      }
      if(config.useBootstrap) {
        this.useBootstrap = config.useBootstrap;
      }
      this.getCmp(function (cmp) {
        // add settings event listener
        cmp(
          "addEventListener",
          [
            "consent",
            (e, o) => {
              that.update();
            },
            false,
          ],
          null
        );
        that.update();
      });
      return this;
    },
    isVideoAllowed: function () {
      if (this.platform === "youtube") {
        return this.youtubeAllowed;
      } else if (this.platform === "vimeo") {
        return this.vimeoAllowed;
      }
      return false;
    },
    addCmpScript: function (callback) {
      const that = this;
      // create script tag
      const cmpScriptTag = window.parent.document.createElement("script");
      // set attributes
      cmpScriptTag.setAttribute("id", "cmp-script");
      cmpScriptTag.setAttribute("type", "text/javascript");
      cmpScriptTag.setAttribute("data-cmp-ab", "1");
      cmpScriptTag.setAttribute(
        "src",
        "https://cdn.consentmanager.net/delivery/autoblocking/" +
          that.cmpId +
          ".js"
      );
      cmpScriptTag.setAttribute(
        "data-cmp-host",
        "b.delivery.consentmanager.net"
      );
      cmpScriptTag.setAttribute("data-cmp-cdn", "cdn.consentmanager.net");
      cmpScriptTag.setAttribute("data-cmp-codesrc", "1");

      const onScriptLoad = function () {
        that.cmpLoaded = true;
        if (window.parent.__cmp) {
          return callback(window.parent.__cmp);
        }
        if (window.__cmp) {
          return callback(window.__cmp);
        }
      };

      // set load event to the script tag
      cmpScriptTag.addEventListener("load", onScriptLoad);
      // append to head unless already there
      if (!window.parent.document.getElementById("cmp-script")) {
        window.parent.document.head.appendChild(cmpScriptTag);
      } else {
        window.parent.document
          .getElementById("cmp-script")
          .addEventListener("load", onScriptLoad);
      }
    },
    getCmp: function (callback) {
      const that = this;
      const cmpFound = (cmp, callback) => {
        if (!that.cmpLoaded) {
          that.cmpLoaded = true;
        }
        callback(cmp);
      };
      if (window.parent.__cmp) {
        return cmpFound(window.parent.__cmp, callback);
      }
      if (window.__cmp) {
        return cmpFound(window.__cmp, callback);
      }
      if (!this.cmpLoaded) {
        that.addCmpScript(callback);
      }
    },
    openConsentScreen: function () {
      const that = this;
      return function (e) {
        e.preventDefault();
        that.getCmp((cmp) => {
          cmp("showScreen", null, (data) => {});
        });
      };
    },
    checkConsent: function (cmp, callback) {
      const that = this;
      // get consent status
      cmp("getCMPData", null, function (data) {
        if ("vendorConsents" in data) {
          if (
            that.vendorIds.youtube in data.vendorConsents
            &&
            data.vendorConsents[that.vendorIds.youtube]
          ) {
            if (!that.youtubeAllowed) {
              that.youtubeAllowed = true;
            }
          } else {
            if (that.youtubeAllowed) {
              that.youtubeAllowed = false;
            }
          }
          if (
            that.vendorIds.vimeo in data.vendorConsents
            &&
            data.vendorConsents[that.vendorIds.vimeo]
          ) {
            if (!that.vimeoAllowed) {
              that.vimeoAllowed = true;
            }
          } else {
            if (that.vimeoAllowed) {
              that.vimeoAllowed = false;
            }
          }
        }
        if (callback) {
          callback();
        }
      });
    },
    getPlatformName: function () {
      if (this.platform === "youtube") {
        return "YouTube";
      } else if (this.platform === "vimeo") {
        return "Vimeo";
      }
      return "";
    },
    giveConsent: function () {
      const that = this;
      return function (e) {
        e.preventDefault();
        that.getCmp((cmp) => {
          if (that.platform === "youtube") {
            cmp("setVendorConsent", [that.vendorIds.youtube, 1], (data) => {
              that.youtubeAllowed = true;
            });
          } else if (that.platform === "vimeo") {
            cmp("setVendorConsent", [that.vendorIds.vimeo, 1], (data) => {
              that.vimeoAllowed = true;
            });
          }
        });
      };
    },
    createIframe: function () {
      const iframe = document.createElement("iframe");
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("allowfullscreen", "true");
      iframe.setAttribute("width", "640");
      iframe.setAttribute("height", "360");
      iframe.setAttribute(
        "allow",
        "accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      );
      return iframe;
    },
    render: function () {
      const that = this;
      if (that.wasVideoAllowed === that.isVideoAllowed()) {
        return;
      } else {
        that.wasVideoAllowed = that.isVideoAllowed();
      }
      // empty wrapper
      const wrapper = document.getElementById(that.wrapperId);
      while (wrapper.firstChild) {
        wrapper.removeChild(wrapper.firstChild);
      }
      if (that.platform === "youtube") {
        if (that.youtubeAllowed) {
          const iframe = that.createIframe();
          iframe.setAttribute(
            "src",
            "https://www.youtube-nocookie.com/embed/" + that.embedCode
          );
          if (that.useBootstrap) {
            const div = document.createElement("div");
            div.setAttribute('class', 'ratio ratio-16x9');
            div.appendChild(iframe);
            wrapper.appendChild(div);
          } else {
            wrapper.appendChild(iframe);
          }
          wrapper.classList.remove('extEmbdVideo');
          return;
        }
      } else if (that.platform === "vimeo") {
        if (that.vimeoAllowed) {
          const iframe = that.createIframe();
          iframe.setAttribute(
            "src",
            "https://player.vimeo.com/video/" +
              that.embedCode +
              "?h=07de2069a1&color=65b32e&byline=0&portrait=0"
          );
          if (that.useBootstrap) {
            const div = document.createElement("div");
            div.setAttribute('class', 'ratio ratio-16x9');
            div.appendChild(iframe);
            wrapper.appendChild(div);
          } else {
            wrapper.appendChild(iframe);
          }
          wrapper.classList.remove('extEmbdVideo');
          return;
        }
      }
      const d = document.createElement("div");
      d.innerHTML =
        '<p><strong>Empfohlener redaktioneller Inhalt</strong></p>An dieser Stelle findest Du einen externen Inhalt von ' +
        that.getPlatformName() +
        ', der aufgrund Deiner Einstellungen deaktiviert wurde. <span id="give-consent-' +
        that.wrapperId +
        '"></span>, um den Inhalt zu erlauben.<p class="pt-2 extEmbdVideoDisclaimer">Ich bin damit einverstanden, dass mir die Inhalte von ' +
        that.getPlatformName() +
        ' angezeigt werden. Personenbezogene Daten können an Drittplattformen übermittelt werden. Mehr dazu in unserer <a href="https://www.maschinenring.de/datenschutz.html" target="_blank">Datenschutzerklärung.</a> Die Zustimmungs-Einstellungen für diesen Anbieter kannst Du jederzeit durch Klick auf das Cookie-Icon links unten wieder ändern.</p>';
      wrapper.appendChild(d);
      const a = document.createElement("a");
      a.setAttribute("href", "#");
      a.innerHTML = "Du kannst hier klicken";
      //a.addEventListener('click', that.openConsentScreen());
      a.addEventListener("click", that.giveConsent());
      document.getElementById("give-consent-" + that.wrapperId).appendChild(a);
      wrapper.classList.add('extEmbdVideo');
    },
    update: function () {
      const that = this;
      that.getCmp((cmp) => {
        that.checkConsent(cmp, function () {
          that.render();
        });
      });
    },
  };
  return CMPVideoConsent.init(config);
};